import React from "react"

import Triangle from "@src/icons/v2/list-triangle.svg"
import DottedLines from "@src/icons/about_us-journeyLines.svg"

import * as styles from "./Journey.module.scss"
import Section from "@src/components/Section"
import Text from "@src/components/Text"
import Row from "../../../components/Row"

const Journey = () => (
  <>
    <Row>
      <h2 className={styles.title}>
        From idea to production, in less than a year.
      </h2>
      <p className={styles.subtitle}>Our Journey</p>
      <section className={styles.journey}>
        <div className={styles.journeyMomentWrapper}>
          <div className={styles.journeyMomentLeft}>
            <div className={styles.journeyMoment}>
              <h2>💡</h2>
              <Text.H4 blueGradient className={styles.journeyMomentTitle}>
                In May 2020, we conceived the Canonic Revolution.
              </Text.H4>
              <Text.Subtitle className={styles.journeyMomentSubtitle}>
                We were tired of having to code repetititve blocks and failed
                find a simpler solution. So we decided to create one.
              </Text.Subtitle>
              <div
                className={styles.journeyMomentLines}
                src={DottedLines}
                alt=""
              />
            </div>
          </div>
          <div className={styles.journeyMomentRight}>
            <div
              className={`${styles.journeyMoment} ${styles.journeyMomentTextRight}`}
            >
              <h2>🤓</h2>
              <Text.H2 className={styles.journeyMomentTitle}>
                For 6 months, we explored features and tested prototypes with
                potential users.
              </Text.H2>
              <Text.Subtitle className={styles.journeyMomentSubtitle}>
                We know that good products take time. The vision to empower
                people to build their own digital products kept us going, day
                after day.
              </Text.Subtitle>
              <div
                className={`${styles.journeyMomentLines} ${styles.journeyMomentLinesInverse}`}
                src={DottedLines}
                alt=""
              />
            </div>
          </div>
          <div className={styles.journeyMomentLeft}>
            <div className={styles.journeyMoment}>
              <h2>🚀</h2>
              <Text.H2 className={styles.journeyMomentTitle}>
                We launched in October 2020 and were ranked #3 on Product Hunt’s
                product of the day.
              </Text.H2>
              <Text.Subtitle className={styles.journeyMomentSubtitle}>
                The first step towards creating a low-code backend development
                platform was finally underway.{" "}
                <strong>“Houston, we have liftoff”</strong>.
              </Text.Subtitle>
              <div
                className={styles.journeyMomentLines}
                src={DottedLines}
                alt=""
              />
            </div>
          </div>
          <div className={styles.journeyMomentRight}>
            <div
              className={`${styles.journeyMoment} ${styles.journeyMomentTextRight}`}
            >
              <h2>🤓</h2>
              <Text.H2 className={styles.journeyMomentTitle}>
                6 months of hardwork and a lot of meetings later ...
              </Text.H2>
              <Text.Subtitle className={styles.journeyMomentSubtitle}>
                We got a lot of feedback, investor interest and collaboration
                opportunities.
              </Text.Subtitle>
              <div
                className={`${styles.journeyMomentLines} ${styles.journeyMomentLinesInverse}`}
                src={DottedLines}
                alt=""
              />
            </div>
          </div>
          <div className={styles.journeyMomentLeft}>
            <div className={styles.journeyMoment}>
              <h2>🚀</h2>
              <Text.H2 className={styles.journeyMomentTitle}>
                In March 2021, we became a US & India registered pre-seed funded
                startup.
              </Text.H2>
              <Text.Subtitle className={styles.journeyMomentSubtitle}>
                It was official. What was born out of a small apartment in
                Mumbai was now a fully-fledged startup with a growing team.
              </Text.Subtitle>
              <div
                className={styles.journeyMomentLines}
                src={DottedLines}
                alt=""
              />
            </div>
          </div>
          <div className={styles.journeyMomentRight}>
            <div
              className={`${styles.journeyMoment} ${styles.journeyMomentTextRight}`}
            >
              <h2>🤓</h2>
              <Text.H2 className={styles.journeyMomentTitle}>
                4 months later, we’re ready to come out of beta.
              </Text.H2>
              <Text.Subtitle className={styles.journeyMomentSubtitle}>
                After a little over a year of building Canonic, we’re excited to
                come out of beta; ready to take on the low-code world.
              </Text.Subtitle>
            </div>
          </div>
        </div>
        <img
          src={Triangle}
          alt="Automate Events without diving into CRON and scripts"
          className={styles.Triangle2}
        />
      </section>
    </Row>
  </>
)

export default Journey
