// extracted by mini-css-extract-plugin
export var card = "AboutUs-module--card--1Yqb8";
export var cards = "AboutUs-module--cards--3hUCl";
export var cardsWrapper = "AboutUs-module--cardsWrapper--xcgvz";
export var contactCard = "AboutUs-module--contactCard--1r1EN";
export var section = "AboutUs-module--section--3BTnk";
export var shapeOneImage = "AboutUs-module--shapeOneImage--25ay5";
export var shapeThree = "AboutUs-module--shapeThree--3vkBi";
export var shapeTwo = "AboutUs-module--shapeTwo--LS-mX";
export var shapeTwoImage = "AboutUs-module--shapeTwoImage--12pa2";
export var showIn = "AboutUs-module--show-in--3L-ki";
export var smallTitle = "AboutUs-module--smallTitle--3beAY";
export var splash = "AboutUs-module--splash--Mbyp0";
export var subtitle = "AboutUs-module--subtitle--1weJD";
export var team = "AboutUs-module--team--2ItC3";
export var teamTextCenter = "AboutUs-module--teamTextCenter--aDtVG";
export var teamWrapper = "AboutUs-module--teamWrapper--1ch1m";
export var title = "AboutUs-module--title--l4GH4";