import React from "react"
import * as styles from "./IconBox.module.scss"

export default function IconBox({
  title,
  subtitle,
  icon: Icon = () => null,
  className = "",
  size = 64,
  horizontal = false,
}) {
  const horizontalClassName = horizontal ? styles.horizontal : ""
  return (
    <div className={`${styles.iconBox} ${horizontalClassName} ${className}`}>
      <div
        style={{ minWidth: size, width: size, height: size }}
        className={styles.iconBoxIcon}
      >
        {!Icon ? null : typeof Icon === "string" ? (
          <img src={Icon} />
        ) : (
          <Icon />
        )}
      </div>
      <div className={styles.iconBoxText}>
        <div className={styles.iconBoxTitle}>{title}</div>
        <div className={styles.iconBoxSubtitle}>{subtitle}</div>
      </div>
    </div>
  )
}
