// extracted by mini-css-extract-plugin
export var Triangle1 = "Journey-module--Triangle1--3p_lF";
export var Triangle2 = "Journey-module--Triangle2--1YxFp";
export var journey = "Journey-module--journey--39gNJ";
export var journeyMoment = "Journey-module--journeyMoment--14TjW";
export var journeyMomentImgRect = "Journey-module--journeyMomentImgRect--1qqQ1";
export var journeyMomentImgRectLeft = "Journey-module--journeyMomentImgRectLeft--1JSl2";
export var journeyMomentLeft = "Journey-module--journeyMomentLeft--2CdbC";
export var journeyMomentLines = "Journey-module--journeyMomentLines--WcSwm";
export var journeyMomentLinesInverse = "Journey-module--journeyMomentLinesInverse--J2Kcm";
export var journeyMomentRight = "Journey-module--journeyMomentRight--2QhXL";
export var journeyMomentSubtitle = "Journey-module--journeyMomentSubtitle--2vpfB";
export var journeyMomentTextRight = "Journey-module--journeyMomentTextRight--32r3C";
export var journeyMomentTitle = "Journey-module--journeyMomentTitle--2A508";
export var journeyMomentWrapper = "Journey-module--journeyMomentWrapper--3TAY0";
export var journeyWrapper = "Journey-module--journeyWrapper--1_BE6";
export var showIn = "Journey-module--show-in--3BfK0";
export var smallTitle = "Journey-module--smallTitle--30Ut_";
export var subtitle = "Journey-module--subtitle--25aGm";
export var title = "Journey-module--title--3Ue56";