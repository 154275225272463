import React from "react"

import Text from "@src/components/Text"
import * as styles from "./OurAim.module.scss"
import Button from "@src/components/Button"
import { DISCORD_INVITE, HELLO_EMAIL_ID } from "@src/const"
const OurAim = () => (
  <div className={styles.ourAim}>
    <div className={styles.ourAimWrapper}>
      <div className={styles.ourAimContent}>
        <Text.H3 whiteTitle noMargin centerText>
          Canonic is rapidly growing and we’d appreciate your input.
        </Text.H3>
        <Text.Subtitle white centerText>
          If you have an idea, suggestion or problem that you’ve faced, reach
          out and help us make Canonic more powerful.
        </Text.Subtitle>

        <div className={styles.ourAimContentButtonWrapper}>
          <a href={DISCORD_INVITE} target="_blank" rel="noreferrer">
            <Button isPrimary className={styles.testimonialsButton}>
              Join Discord
            </Button>
          </a>
          <a href={`mailto:${HELLO_EMAIL_ID}`} target="_blank" rel="noreferrer">
            <Button className={styles.testimonialsButton}>Write to Us</Button>
          </a>
        </div>
      </div>
    </div>
  </div>
)

export default OurAim
