import React from "react"
import IconBox from "../../../components/IconBox/IconBox"
import Row from "../../../components/Row"
import * as styles from "./Features.module.scss"

const Features = ({
  features,
  size = 100,
  horizontal,
  numPerRow: numPerRowProps,
  className,
}) => {
  const horizontalClassName = horizontal ? styles.horizontal : ""
  const numPerRow = numPerRowProps || (horizontal ? 3 : 4)
  return (
    <ul
      className={`${styles.automatedWorkflowsGrid} ${horizontalClassName} ${className}`}
    >
      {features.map(({ title, subtitle, icon }) => (
        <div
          style={{
            flexBasis: `calc(${100 / numPerRow}% - 108px)`,
            minWidth: `calc(${100 / numPerRow}% - 108px)`,
            maxWidth: `calc(${100 / numPerRow}% - 108px)`,
          }}
        >
          <IconBox
            horizontal={horizontal}
            size={size}
            title={title}
            subtitle={subtitle}
            icon={icon}
          />
        </div>
      ))}
    </ul>
  )
}

export default Features
