import React from "react"

import getAppUrl from "@src/utils/getAppUrl"
import Section from "@src/components/Section"
import SplashWithIllustration from "@src/components/SplashWithIllustration"
import Text from "@src/components/Text"
import CommunityCallout from "@src/components/CommunityCallout"
import Callout from "@src/components/Callout"
import Layout from "@src/components/Layout"
import OurAim from "./components/OurAim"
import Journey from "./components/Journey"

import GraphShape from "@src/icons/v2/graph-shape.svg"
import Pratham from "@src/icons/photos/pratham.png"
import Simran from "@src/icons/photos/simran.png"
import Aditi from "@src/icons/photos/aditi.png"
import Tanisha from "@src/icons/photos/tanisha.png"
import Ajay from "@src/icons/photos/ajay.png"
import Soumik from "@src/icons/photos/soumik.png"
import Aakash from "@src/icons/photos/aakash.png"
import Athul from "@src/icons/photos/athul.png"
import Ayaan from "@src/icons/photos/ayaan.png"
import OG from "@src/icons/og/OG_About_Us.jpeg"

import * as styles from "./AboutUs.module.scss"
import Splash from "../Features/components/Splash"
import Features from "../Features/components/Features"
import Row from "../components/Row"

const AboutUs = ({ location }) => {
  const appUrl = getAppUrl.hook(location)
  return (
    <Layout
      title="About Us"
      description="Develop without the barrier of technical expertise."
      url="https://canonic.dev/about-us"
      ogImage={OG}
    >
      <Splash
        title="Develop without the barrier of technical expertise."
        subtitle="Canonic strives to make digital product development easier and more accessible."
      />
      <Journey />
      <OurAim />
      <Row>
        <h2 className={styles.title}>Meet the team</h2>
        <p className={styles.subtitle}>The faces behind the product.</p>
        <Features
          size={200}
          features={[
            {
              icon: Pratham,
              title: "Pratham Agrawal",
              subtitle: "Founder & CEO",
            },
            {
              icon: Simran,
              title: "Simranjot Singh",
              subtitle: "Co-Founder & CPO",
            },
            { icon: Aditi, title: "Aditi Jain", subtitle: "Co-Founder & CDO" },

            {
              icon: Tanisha,
              title: "Tanisha Chouhan",
              subtitle: (
                <>
                  Marketing & Content <br /> Developer
                </>
              ),
            },
            {
              icon: Ajay,
              title: "Ajay Jarhad",
              subtitle: "Full Stack Engineer",
            },
            {
              icon: Soumik,
              title: "Soumik Chaudhuri",
              subtitle: "Full Stack Engineer",
            },
            {
              icon: Aakash,
              title: "Aakash Chauhan",
              subtitle: "Graphics Designer",
            },
            {
              icon: Athul,
              title: "Athul K Kumar",
              subtitle: "Full Stack Intern",
            },
            {
              icon: Ayaan,
              title: "Ayaan Belim",
              subtitle: "Sales Intern",
            },
          ]}
        />
      </Row>
      <Callout location={location} title="aboutUs" />
      <CommunityCallout />
    </Layout>
  )
}

export default AboutUs
